import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { PublicRoutes } from "./Routes/PublicRoutes";
import { ThemeProvider } from "@material-tailwind/react";
import Login from "./Pages/Public/Login";

const App = () => {
    return (
        <ThemeProvider>
            <Router>
                <Routes>
                    <Route path="/*" element={<PublicRoutes />} />
                    <Route path="/login" element={<Login/>} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
};

export default App;
