import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { GlobalNavbar } from "../Components/Front-End/Navbar/Navbar";
import { FooterWithSocialLinks } from "../Components/Front-End/Footer/Footer";
import AboutPage from "../Pages/Public/AboutUs";
import HomePage from "../Pages/Public/Home";
import ContactUsPage from "../Pages/Public/ContactUs";
import Team2Props from "../Pages/Public/Coach";
import ExecutiveTeam from "../Pages/Public/ExecutiveTeam";
import EventsPage from "../Pages/Public/Events";
import BlogsPage from "../Pages/Public/Blogs";
import SchedulePage from "../Pages/Public/Schedule";
import CanSkatePage from "../Pages/Public/CanSkate";
import PreCanSkatePage from "../Pages/Public/PreCanSkate";
import AdultCanSkatePage from "../Pages/Public/AdultCanSkate";
import CanPowerSkatePage from "../Pages/Public/CanPowerSkate";
import IntroToFigureSkatingPage from "../Pages/Public/IntroToFigureSkating";
import AdultFigureSkatingPage from "../Pages/Public/AdultFigureSkating";
import StarSkatePage from "../Pages/Public/StarSkate";
import CompetitiveSkatePage from "../Pages/Public/CompetetiveSkate";
import DropInPage from "../Pages/Public/DropIn";
import RecommendedVendorsPage from "../Pages/Public/RecommendedVendors";
import FigureSkateParentInformationPage from "../Pages/Public/FigureSkateParentInformation";
import LearnToSkateInformationPage from "../Pages/Public/LearnToSkateParentInformation";
import APIEndPointTestPage from "../Pages/Public/APIEndPointTest";
import ReactGA from "react-ga4";
import React, { useEffect } from "react";

ReactGA.initialize("G-JRWRLDDGCR");

export const PublicRoutes = () => {
    return (
        <>
            <TrackPageViews />
            <GlobalNavbar />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/aboutus" element={<AboutPage />} />
                <Route path="/coach" element={<Team2Props />} />
                <Route path="/contactus" element={<ContactUsPage />} />
                <Route path="/executives" element={<ExecutiveTeam />} />
                <Route path="/events" element={<EventsPage />} />
                <Route path="/blogs" element={<BlogsPage />} />
                <Route path="/schedule" element={<SchedulePage />} />

                {/* Programs */}
                <Route path="/adultcanskate" element={<AdultCanSkatePage />} />
                <Route path="/canpower" element={<CanPowerSkatePage />} />
                <Route path="/canskate" element={<CanSkatePage />} />
                <Route path="/precanskate" element={<PreCanSkatePage />} />
                <Route path="/introtofigureskating" element={<IntroToFigureSkatingPage />} />
                <Route path="/adultfigureskating" element={<AdultFigureSkatingPage />} />
                <Route path="/starskate" element={<StarSkatePage />} />
                <Route path="/competitiveskate" element={<CompetitiveSkatePage />} />
                <Route path="/dropin" element={<DropInPage />} />
                <Route path="/recommendedvendors" element={<RecommendedVendorsPage />} />
                <Route path="/fsparentinformation" element={<FigureSkateParentInformationPage />} />
                <Route path="/ltsparentinformation" element={<LearnToSkateInformationPage />} />
                <Route path="/apiendpointtest" element={<APIEndPointTestPage />} />

                {/* Redirect for undefined routes */}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
            <FooterWithSocialLinks />
        </>
    );
};

const TrackPageViews = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [location]);

    return null;
};
