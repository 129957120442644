import React from "react";
import {AppProvider} from "@toolpad/core";
import WebsiteLogo from "../../../assets/main-website-logo-trans.png"
import BackgroundImage from "../../../assets/carousel-banner-images/index-carosel-img-one.webp"

import {
    SignInPage,
} from '@toolpad/core/SignInPage';
import {Box} from "@mui/system";

const BRANDING = {
    logo: (
        <img
            src={WebsiteLogo}
            alt="MUI logo"
            style={{ height: 75 }}
        />
    ),
    title: 'Federation Skating Club',
};

const login = () => {
    const providers = [{ id: 'credentials', name: 'Email and Password' }];

    return (
        <AppProvider branding={BRANDING}>
            <Box
                sx={{
                    position: "relative",
                    height: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundImage: `url(${BackgroundImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    "&::before": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(255, 255, 255, 0.2)", // Light overlay for contrast
                        backdropFilter: "blur(5px)", // Slight blur effect
                        zIndex: 1,
                    },
                }}
            >
                <Box sx={{ position: "relative", zIndex: 2 }}>
                    <SignInPage providers={providers} slotProps={{ emailField: { autoFocus: false } }} />
                </Box>
            </Box>
        </AppProvider>
    );
};

export default login;