// src/pages/AboutPage.js
import React from 'react';
import {Blogs} from "../../Components/Front-End/Blogs/Blogs";
import {Banner} from "../../Components/Front-End/StaticBanner/StaticBanner";

const BlogsPage = () => {
    return (
        <div>
            <Banner/>
           <Blogs/>
        </div>
    );
};

export default BlogsPage;